var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon mt-2 mr-3",
                  attrs: { "hex-color": "#0f88ef", name: "chart-bar" },
                }),
                _vm._v(" Comparision Tool "),
              ]
            },
            proxy: true,
          },
          {
            key: "left-buttons",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn kt-subheader__btn btn-bold btn-upper mr-2 btn-label-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.chartSettings.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Settings ")]
                ),
                _c("toggle-button", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.bottomleft",
                      modifiers: { hover: true, bottomleft: true },
                    },
                  ],
                  staticClass: "m-2",
                  attrs: {
                    height: 28,
                    width: 56,
                    "font-size": 18,
                    title: _vm.showPercentages
                      ? "Show Percentages"
                      : "Show Raw Values",
                    labels: { checked: "💯", unchecked: "#️" },
                    color: { checked: "#75C791", unchecked: "#0f88ef" },
                  },
                  model: {
                    value: _vm.showPercentages,
                    callback: function ($$v) {
                      _vm.showPercentages = $$v
                    },
                    expression: "showPercentages",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4",
        },
        [
          _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { ref: "chartDiv", staticClass: "chartDiv" }),
            ]),
          ]),
          _vm.students.length
            ? _c("div", { staticClass: "kt-portlet" }, [
                _c(
                  "div",
                  { staticClass: "kt-portlet__body" },
                  [_c("BiliteracySealHotTable")],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }