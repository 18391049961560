<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <SVGIcon
                hex-color="#0f88ef"
                name="chart-bar"
                class="kt-svg-icon mt-2 mr-3"
            />

            Comparision Tool
            <!-- <small class="ml-3">{{ lastUpdated }}</small> -->
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
        <template #buttons>
            <button
                type="button"
                class="btn kt-subheader__btn btn-bold btn-upper mr-2 btn-label-primary"
                @click.stop.prevent="chartSettings"
            >
                Settings
            </button>

            <toggle-button
                v-model="showPercentages"
                v-b-tooltip.hover.bottomleft
                :height="28"
                :width="56"
                :font-size="18"
                class="m-2"
                :title="showPercentages ? 'Show Percentages' : 'Show Raw Values'"
                :labels="{checked: '💯', unchecked: '#️'}"
                :color="{checked: '#75C791', unchecked: '#0f88ef' }"
            />
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pr-2 pl-4">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div ref="chartDiv" class="chartDiv" />
            </div>
        </div>

        <div v-if="students.length" class="kt-portlet">
            <div class="kt-portlet__body">
                <BiliteracySealHotTable />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import Types from '../store/Types';

import panelMixins from '../store/mixins/panelMixins';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import BiliteracySealHotTable from '../components/BiliteracySealHotTable.vue';
import * as network from '../network';

export default Vue.extend({
    name: 'BiliteracySealAnalysis',
    components: {
        GeneralSubHeader,
        BiliteracySealHotTable,
    },
    mixins: [panelMixins],
    data() {
        return {
            key: 0,
            loading: false,
            showPercentages: true,
            students: [],
        };
    },
    computed: {
        schoolYear() {
            return this.$store.state.biliteracySeal.schoolYear;
        },
        dataPoints() {
            return this.$store.state.biliteracySeal.dataPoints;
        },
        columns() {
            return this.$store.state.database.studentDataView.columns
                .filter((c) => c.enabled && !['achievementLevel', 'badgeTitle', 'googleEmail'].includes(c.name));
        },
    },
    watch: {
        showPercentages() {
            this.mountChart();
        },
        schoolYear() {
            this.mountChart();
        },
        '$route.query': function () {
            this.mountChart();
        },
    },
    mounted() {
        setTimeout(() => {
            this.mountChart();
            this.populateStudents();
        }, 100);
    },
    beforeDestroy() {
        // if (this.root) this.root.dispose();
    },
    methods: {
        addDataPoint(dataPoint) {
            this.$store.commit(Types.mutations.ADD_BILTERACY_DATA_POINT, dataPoint);
            setTimeout(() => {
                this.addSearch = null;
                this.key += 1;
            }, 10);
        },
        chartSettings() {
            this.$_panelMixins_openChartCompareForDashboard();
            this.$store.commit(Types.mutations.OPEN_QUICK_PANEL);
        },
        mountChart() {
            const v = this;
            am5.array.each(am5.registry.rootElements, (r) => { if (r) r.dispose(); });

            const { chartDiv } = this.$refs;

            const root = am5.Root.new(chartDiv);
            const { showPercentages, dataPoints } = this;

            // Create chart
            // https://www.amcharts.com/docs/v5/charts/xy-chart/
            const chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'panX',
                wheelY: 'zoomX',
                paddingLeft: 0,
                marginTop: 20,
                layout: root.verticalLayout,
            }));

            // // Add scrollbar
            // // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
            // chart.set('scrollbarX', am5.Scrollbar.new(root, {
            //     orientation: 'horizontal',
            //     marginBottom: 40,
            // }));

            const data = dataPoints.map((p) => createDataPoint(p));

            // Create axes
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
            const xRenderer = am5xy.AxisRendererX.new(root, {
                minorGridEnabled: true,
            });
            const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                categoryField: 'year',
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            }));

            xRenderer.grid.template.setAll({
                location: 1,
            });

            xAxis.data.setAll(data);

            const renderer = am5xy.AxisRendererY.new(root, { strokeOpacity: 0.1 });

            const props = {
                min: 0,
                renderer: null,
            };

            if (showPercentages) {
                props.max = 100;
                props.numberFormat = "#'%'";
                props.strictMinMax = true;
                props.calculateTotals = true;
            }

            props.renderer = renderer;

            const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, props));

            // Add legend
            // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
            const legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                marginTop: 40,
            }));

            // Add series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            function makeSeries(name, fieldName) {
                const seriesProps = {
                    name,
                    stacked: true,
                    xAxis,
                    yAxis,
                    valueYField: fieldName,
                    categoryXField: 'year',
                };
                if (showPercentages) {
                    seriesProps.valueYShow = 'valueYTotalPercent';
                }
                const series = chart.series.push(am5xy.ColumnSeries.new(root, seriesProps));

                series.columns.template.setAll({
                    tooltipText: showPercentages ? "{name}, {categoryX}:{valueYTotalPercent.formatNumber('#.#')}%" : '{name}, {categoryX}: {valueY}',
                    tooltipY: am5.percent(10),
                    maxWidth: 100,
                });
                series.data.setAll(data);

                // Make stuff animate on load
                // https://www.amcharts.com/docs/v5/concepts/animations/
                series.appear();

                series.bullets.push(() => am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: showPercentages ? "{valueYTotalPercent.formatNumber('#.#')}%" : '{valueY}',
                        fill: root.interfaceColors.get('alternativeText'),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                }));

                // Add on click
                series.columns.template.events.on('click', (ev) => {
                    v.sectionClicked();
                });

                legend.data.push(series);
            }

            makeSeries('White', 'white');
            makeSeries('Black or African American', 'black');
            makeSeries('Asian or Native Hawaiian or Other Pacific Islander', 'asian');
            makeSeries('Hispanic or Latino or of Spanish Origin', 'hispanic');
            makeSeries('American Indian or Alaska Native', 'indian');
            makeSeries('Multiracial', 'multiracial');

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            chart.appear(1000, 100);
        },
        populateStudents() {
            const v = this;
            network.students.ellStudents({}, (err, res) => {
                if (err) v.showError(err);
                const { students } = res;
                v.students = students;
                v.populateDataView(null);
            });
        },
        populateDataView(list) {
            const { columns, students } = this;
            const rows = (list || students)
                .sort((a, b) => {
                    if (a.lastName < b.lastName) return -1;
                    if (a.lastName > b.lastName) return 1;
                    return 0;
                })
                .map((s) => {
                    const record = columns.map((c) => {
                        const column = { ...c };
                        const { source, format } = column;
                        const student = { ...s };

                        const cell = {
                            column,
                            cellValue: '',
                            color: null,
                        };

                        if (source == 'student') {
                            cell.cellValue = format(student);
                        }

                        return cell;
                    });

                    return record;
                });

            // add the header row
            const record = columns.map((c) => {
                const column = { ...c };
                return {
                    column,
                    cellValue: column.title,
                };
            });
            rows.unshift(record);

            this.$store.commit(Types.mutations.SET_STUDENT_DATA_VIEW_ROWS, rows);
            window.scrollTo(0, 800);
        },
        sectionClicked() {
            const { students } = this;
            const shuffled = students.sort(() => 0.5 - Math.random());
            this.populateDataView(shuffled.slice(0, 25));
        },
    },
});

const savedDataPoints = [];
function createDataPoint(dataPoint) {
    const { schoolYear, name, type } = dataPoint;
    const title = `${schoolYear}\n${name}`;
    const exists = savedDataPoints.find((d) => d.year == title);
    if (exists) return exists;

    const stateTotals = [{
        year: '2019',
        white: 2281,
        black: 211,
        asian: 536,
        hispanic: 1456,
        indian: 19,
        multiracial: 106,
    }, {
        year: '2020',
        white: 3237,
        black: 242,
        asian: 628,
        hispanic: 1248,
        indian: 46,
        multiracial: 91,
    }, {
        year: '2021',
        white: 4069,
        black: 431,
        asian: 949,
        hispanic: 1209,
        indian: 220,
        multiracial: 236,
    }];

    const stateTotal = stateTotals.find((t) => t.year == schoolYear);
    if (!stateTotal) {
        const [first] = stateTotals;
        return first;
    }
    let divisor = 1;
    if (type == 'Regions') {
        divisor = 2;
    } else if (type == 'Districts') {
        divisor = 4;
    } else if (type == 'Schools') {
        divisor = 8;
    }

    stateTotal.year = title;
    stateTotal.white = randomIntFromInterval(0, Math.floor(stateTotal.white / divisor));
    stateTotal.black = randomIntFromInterval(0, Math.floor(stateTotal.black / divisor));
    stateTotal.asian = randomIntFromInterval(0, Math.floor(stateTotal.asian / divisor));
    stateTotal.hispanic = randomIntFromInterval(0, Math.floor(stateTotal.hispanic / divisor));
    stateTotal.indian = randomIntFromInterval(0, Math.floor(stateTotal.indian / divisor));
    stateTotal.multiracial = randomIntFromInterval(0, Math.floor(stateTotal.multiracial / divisor));

    savedDataPoints.push({ ...stateTotal });

    return stateTotal;
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

</script>

<style lang="scss">

.chartDiv {
    height: 700px;
    width: 100%;
}

td {
    vertical-align: middle !important;
}
</style>
